.layout {
  z-index: 2;
}

.section {
  @extend %glob-animation-to-up;

  --p-margin: 0 0 #{rem(40)};
  --p-max-width: #{rem(690)};

  padding: rem(80) 0;

  overflow: hidden;

  &.--right {
    --container-align-items: flex-end;

    margin-left: auto;

    text-align: right;
  }

  &.--center {
    --container-align-items: center;
    --p-max-width: #{auto};
    text-align: center;
  }
}

.caption {
  margin: var(--caption-margin, 0 0 rem(24) 0);

  font-weight: bold;
  font-size: rem(52);
  line-height: 122%;

  color: currentColor;

  &.--h2 {
    font-size: rem(40);
  }

  &.--h3 {
    font-size: rem(28);
  }

  @include max-wide(phonesLg) {
    font-size: rem(48);

    &.--h2 {
      font-size: rem(32);
    }

    &.--h3 {
      font-size: rem(22);
    }
  }
}

.plash-full {
  position: fixed;

  z-index: 0;

  width: 100vw;
  height: 100vh;

  background: url("../assets/images/bg.jpg") no-repeat top center /cover;
  opacity: 0.14;

  overflow: hidden;

  &, &:before {
    @extend %position-full;
  }

  &:before {
    position: absolute;
    z-index: 1;

    content: '';

    background: linear-gradient(0deg, rgba(#fff, 0.4) -43%, transparent);
  }
}

.list {
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: rem(10);

  font-size: rem(20);

  &:not(:last-child) {
    margin: var(--list-padding, 0 0 rem(24));
  }

  li {
    display: inline-flex;
    align-items: center;
    gap: rem(10);

    margin-right: rem(10);

    &:before {
      color: $primary;
      content: '♦';

      transform: scale(.6);
    }
  }
}

.group-buttons {
  display: flex;
  align-items: center;
  gap: rem(16);
}
