.ivf {
  @extend %section;

  --p-max-width: auto;
  padding-bottom: 0;

  &__block {
    @extend %section-block;

    p:last-of-type{
      margin: 0;
    }
  }
}
