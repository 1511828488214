.welcome {
  --container-justify-content: center;
  --container-align-items: center;
  --container-flex-flow: column;
  --container-position: relative;

  --welcome-pic-rotate: 0deg;

  position: relative;

  min-height: max-content;
  //max-height: rem(790);

  margin: calc(var(--header-height) * -1) 0 0;
  padding: calc(var(--header-height) + rem(178)) 0 10vh;

  color: $light;
  text-align: center;

  opacity: 1;
  animation: none !important;

  filter: drop-shadow($sh-card);

  &__pre-title, &__text, &__left-img, .caption, &__icons {
    @extend %glob-animation-to-up;
  }

  &__pre-title {
    margin: 0 0 rem(5);

    font-size: rem(36);
    font-weight: bold;
    line-height: 110%;

    &.--l2 {
      margin: rem(-10) 0 rem(10);


      font-size: rem(28);

      opacity: 0.54 !important;
    }
  }

  &__text {
    margin: rem(14) 0 rem(38);

    max-width: rem(530);

    font-size: rem(24);
    line-height: 150%;
  }

  &__pic {
    position: absolute;
    bottom: 0;
    left: var(--welcome-pic-left, 50%);
    z-index: -1;

    width: var(--welcome-pic-width, 100%);
    height: var(--welcome-pic-height, 100vh);;

    margin: 0 auto;

    border-radius: var(--welcome-pic-border-radius, 0 0 50% 50%);;
    //border: var(--welcome-pic-border, rem(9) solid $light);
    box-shadow: inset 0 rem(4) rem(250) rgba(0, 0, 0, 0.12);

    transform: rotate(var(--welcome-pic-rotate)) translateY(-1vw) translateX(-50%);
    transition: transform;

    animation: smooth-opacity $transition-start-delay $transition-start-duration $transition-function 1 forwards;

    overflow: hidden;

    &:before {
      position: absolute;
      top: -1vh;
      right: -10vh;
      bottom: -5vw;
      left: -10vh;
      height: 150%;

      content: '';

      background: url("../assets/images/welcome.jpg") no-repeat top center /cover;

      transform: rotate(calc(var(--welcome-pic-rotate) * -1)) scale(var(--welcome-pic-scale, 1)) ;
    }

    &:after {
      @extend %position-full;

      position: absolute;
      z-index: 2;

      content: '';

      background: $primary;
      opacity: 0.76;
    }
  }

  &__icons {
    display: inline-flex;
    align-items: center;
    gap: 5vw;

    margin: 7vw auto rem(-22);

    img {
      width: 3em;
      height: 3em;

      margin: $contentGutterX2 0 rem(8);

      object-fit: contain;
      object-position: center;

      opacity: 0.8;

      &:nth-child(1) {
        transform: matrix(1, 0, 0, 1, 0, 0);
      }

      &:nth-child(2) {
        transform: matrix(1, 0, 0, 1, 0, calc(var(--welcome-tr, 1) * 15));
      }

      &:nth-child(3) {
        transform: matrix(1, 0, 0, 1, 0, calc(var(--welcome-tr, 1) * 25));
      }

      &:nth-child(4) {
        transform: matrix(1, 0, 0, 1, 0, calc(var(--welcome-tr, 1) * 15));
      }

      &:nth-child(5) {
        transform: matrix(1, 0, 0, 1, 0, 0);
      }
    }
  }

  @media (max-width: 1450px) {
    //--welcome-pic-scale: -23vw;
    //--welcome-pic-translate-y: 1.2;
  }

  @include max-wide(laptop) {
    --welcome-pic-left: 50%;
    //--welcome-pic-2-size: 30vw;

    --welcome-pic-border-radius: #{0 0 50vw 50vw};
  }

  @include max-wide(phonesLg) {
    --container-align-items: center;
    --welcome-pic-rotate: 0;
    --welcome-pic-border: none;

    --welcome-pic-width: revert;
    --welcome-pic-left: unset;
    --welcome-pic-border-radius: #{0 0 rem(250) rem(250)};

    text-align: center;

    &__pre-title {
      font-size: rem(28);
    }
  }

  @media (max-width: 430px) {
    --welcome-tr: 1.9;
  }

  @media (max-height: 780px) {
    --welcome-pic-height: 110vh;
  }

  @media (max-height: 570px) {
    min-height: rem(500)
  }
}
