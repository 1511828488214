.ps {
  --container-align-items: center;
  --p-margin: 0 0;
  --p-max-width: none;

  font-weight: 600;


  &__pic {
    min-width: rem(350);
    min-height: rem(350);


    margin: 0 rem(35);

    border-radius: 50%;

    overflow: hidden;
  }

  @include min-wide(phonesLg) {
    --container-flex-flow: row;

  }

  @include max-wide(phonesLg) {
    --p-margin: #{rem(48) rem(10) 0};

    text-align: center!important;
  }
}
