.egg-donation {
  @extend %section;

  --p-max-width: auto;

  max-width: 100%;

  padding-bottom: 0;

  &__block {
    @extend %section-block;
  }
}
